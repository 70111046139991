@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;0,600;1,400&display=swap");
@import "./reset";
@import "./mediaQueries";
@import "./colors";
@import "./baseStyles";

body {
  font-family: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  background-color: $brown-color;
}

h1 {
  font-size: 4rem;
}

h4 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

h6 {
  font-size: 1.2rem;
  font-weight: 500;
}

h1,
h4 {
  text-transform: uppercase;
}

.link {
  text-decoration: underline;
}

.gutter {
  margin: 48px 24px;

  &#special-gutter {
    margin-top: 24px;
  }
}

.text__container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.center {
  text-align: center;
}

.section {
  &.quote {
    font-size: 1.5rem;
    font-style: italic;
    text-align: center;
    color: $brown-color;
    display: flex;
    justify-content: center;
  }

  .quote__container {
    max-width: 300px;
  }

  &#thanks {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
}

.main__container {
  background-color: $light-color;
  padding-bottom: 1rem;

  @include tablet {
    max-width: 768px;
    margin: 4rem auto;
    box-shadow: 1rem 1rem rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    overflow: hidden;
  }

  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e66c09;
    position: relative;
    z-index: 0;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(130, 85, 85, 0.4);
      z-index: -1;
    }

    .hero__text-container {
      color: $light-color;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
